import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const About = (props) => {
    const query = useStaticQuery(
        graphql`
            query AboutImg {
                contentfulAsset(title: { eq: "About Ola" }) {
                    gatsbyImageData(
                        width: 400
                        placeholder: TRACED_SVG
                        formats: JPG
                    )
                }
            }
        `
    )

    const aboutImage = getImage(query.contentfulAsset.gatsbyImageData)

    return (
        <div className="about-ola">
            <div className="container">
                <div className="image">
                    <GatsbyImage image={aboutImage} />
                </div>
                <div className="content">
                    <h3>
                        Aleksandra (Ola) Stacha-Fleming, the founder of
                        Longevity Lab&#174;, combines her life experience and
                        years of helping clients of all ages to foster an
                        evolving curriculum of training programs. After working
                        mostly with clients of age 60+ she now fully dedicates
                        her practice to this demographic that is unfortunately
                        often neglected and underserved.
                    </h3>
                    <p>
                        Ola is a Functional Aging Specialist certified by the
                        Functional Aging Institute, she is also a Brain Health
                        trainer- with certification that allows her to work with
                        people who suffer from Dementia, Alzheimer’s disease and
                        other cognitive impairments. She holds a bachelor’s
                        degree in Public Health with a Paramedic Specialization
                        and is an NCSF certified personal trainer. She’s a
                        Functional Movement trainer and Buff Bones Instructor
                        (medically endorsed program for people with osteoporosis
                        and/ or women pre and post menopause). The practice
                        includes: consultations, specific programing, training
                        sessions in home, outdoors, at the gym, in assisted
                        living homes and with memory care units at senior living
                        facilities.{" "}
                    </p>
                    {!props.nobutton && (
                        <Link to="/about/" className="button centered">
                            Read more about us
                        </Link>
                    )}
                    {props.nobutton && (
                        <a
                            className="button"
                            href="mailto:info@longevitylabnyc.com"
                        >
                            Contact Us
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

export default About
