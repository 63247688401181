export const testimonialsData = [
    {
        text: "I've been working with Ola for two years. She's a terrific trainer, both challenging and encouraging. Our sessions encompass strength training, cardio and stretching while also tackling my specific issues, like balance for instance. Ola has also been particularly good at adapting our workouts when I've had medical issues. Our sessions are energizing and, because Ola is interesting and personable, they're really fun too!",
        author: " Mary Hilgeman"
    },
    {
        text: "She has an easy going manner and her confidence in & dedication to my personal success is what keeps me coming back. I highly recommend her.",
        author: "Ellen Erwitt"
    },
    {
        text: "Ola began as my personal trainer, turned into my personal therapist and is now an invaluable part of my extended family - a trusted friend who changes the lives of everyone she trains.",
        author: "#1 Best selling New York Times Author Rick Smolan"
    },
    {
        text: "So far Ola gifted me ten years. Having lived through some personal tragedies at 70 I thought that I was finished. Ola’s training program and coaching brought me back physically and emotionally. Entering my 80’s with confidence!",
        author: "Former Director of Photography NY Daily News, Eric Meskauskas"
    },
    {
        text: "Training for life. Ola has guided me to fitness. She plans training sessions to build strength, endurance and balance. Ola works around the struggles— soreness, injuries, always making the most of a training session. She motivates me to work hard, to feel fit and confident",
        author: "Evan Sarzin"
    },
    {
        text: "Ola has been my trainer for a decade, but she really has been much more. I consider her to be a friend, confidant, and inspiration. Whatever Ola wants, Ola gets; and I mean that in the best of ways.",
        author: "Jacqueline Aranoff"
    }
]