import pillar1 from "../../static/images/pillars/pillar-1.jpg"
import pillar2 from "../../static/images/pillars/pillar-2.jpg"
import pillar3 from "../../static/images/pillars/pillar-3.jpg"
import pillar4 from "../../static/images/pillars/pillar-4.jpg"
import pillar5 from "../../static/images/pillars/pillar-5.jpg"


export const pillarsData = [
    {
        id: 1,
        title: "Gain strength for a functional everyday moments",
        excerpt: "Having strong muscles can mean the difference between only being able to complete a 10-minute walk versus an hour long walk or getting off a chair or couch independently versus needing assistance. Using exercise to build strong muscles is going to help you keep doing all the things you love like playing with your grandchildren, gardening, walking with minimal discomfort or pain. The key to doing this is to build muscle strength!",
        image: pillar1,
        link: "pillar-1"
    },
    {
        id: 2,
        title: "Avoid injuries and falls by retraining balance",
        excerpt: "Falls are the leading cause of injury related visits to emergency departments in the United States and the primary etiology of accidental deaths in persons over the age of 65. Risk factors for falls in the elderly include increasing age, medication use, cognitive impairment and sensory deficits.",
        image: pillar2,
        link: "pillar-2"
    },
    {
        id: 3,
        title: "Improve brain function to stave off cognitive decline, depression and anxiety",
        excerpt: "The idea that physical activity improves brain health is well established by now. Exercise increases neurons in the hippocampus which is essential for memory creation and storage, while also improving thinking skills. In older adults regular, physical activity helps slow the usual loss of brain volume which may help to prevent age related memory loss and possibly lower the risk of dementia. Working out improves blood flow to the brain, promotes cardiovascular health, reduces inflammation and lowers levels of stress hormones. ",
        image: pillar3,
        link: "pillar-3"
    },
    {
        id: 4,
        title: "Delay the onset of chronic disease and ailments or manage existing conditions",
        excerpt: "If you have a chronic condition, regular exercise can help you manage symptoms and improve your health. Aerobic exercise can help improve your heart health and endurance and aid in weight loss. Strength training can improve muscle strength and endurance, make it easier to do daily activities, slow disease related declines in muscle strength and provide stability to joints. Flexibility exercises may help you to have optimal range of motion about your joints, so they can function best, and stability exercises may reduce risk of falls.",
        image: pillar4,
        link: "pillar-4"
    },
    {
        id: 5,
        title: "Add quality years to your life",
        excerpt: "The National Institute of Health defines active life expectancy as years of life without disease and without physical and mental/thinking disability. This is an important distinction from just life expectancy because we aren’t just trying to add more years, we want more years that we can enjoy. It doesn’t matter how old you are but how you are old (er)!Our genetics only make up about 1/3 of the equation for active life expectancy (at least to about age 90). The other two thirds come from a combination of lifestyle choices but especially nutrition and exercise.",
        image: pillar5,
        link: "pillar-5"
    }
]
