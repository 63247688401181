import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// import { graphql } from 'gatsby'
// import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/ui/hero'
import Pillars from '../components/ui/pillars'
import About from '../components/ui/about'
import Carousel from '../components/carousel'
import Articles from '../components/articles'
import LongevitySessionsBanner from '../components/longevity-sessions-banner'
// import ArticlePreview from '../components/article-preview'

import logo from "../../static/images/logo.svg"



class RootIndex extends React.Component {
  render() {
    // const posts = get(this, 'props.data.allContentfulBlogPost.nodes')
    // const [author] = get(this, 'props.data.allContentfulPerson.nodes')

    return (
      <Layout location={this.props.location}>
        <Hero />
        <Pillars homepage/>
        <About />
        <Carousel />
        <Articles />
        <LongevitySessionsBanner />
        {/* <ArticlePreview posts={posts} /> */}
      </Layout>
    )
  }
}

export default RootIndex


// export const query = 
//   graphql`
//       query HeroImgQuery {
//           contentfulAsset(contentful_id: {eq: "2EfSZWCJhwh018XdkYJI8o"}) {
//               gatsbyImageData(width: 600, placeholder: BLURRED, formats: JPG)
//           }
//       }
//   `

// export const pageQuery = graphql`
//   query HomeQuery {
//     allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
//       nodes {
//         title
//         slug
//         publishDate(formatString: "MMMM Do, YYYY")
//         tags
//         heroImage {
//           gatsbyImageData(
//             layout: FULL_WIDTH
//             placeholder: BLURRED
//             width: 424
//             height: 212
//           )
//         }
//         description {
//           childMarkdownRemark {
//             html
//           }
//         }
//       }
//     }
//     allContentfulPerson(
//       filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
//     ) {
//       nodes {
//         name
//         shortBio {
//           shortBio
//         }
//         title
//         heroImage: image {
//           gatsbyImageData(
//             layout: CONSTRAINED
//             placeholder: BLURRED
//             width: 1180
//           )
//         }
//       }
//     }
//   }
// `
