import React from "react"

const data = [
    {
        website: "WELL+GOOD",
        title: "3 Things a Good at-Home Workout Should Always Have, According to a Longevity Trainer",
        url: "https://www.wellandgood.com/best-exercises-for-longevity/",
    },
    {
        website: "WELL+GOOD",
        title: "The 17 Best Longevity Tips Experts Taught Us in 2020",
        url: "https://www.wellandgood.com/best-longevity-tips-2020/",
    },
    {
        website: "LIVESTRONG.COM",
        title: "4 Things Longevity Experts Do Every Day for Healthy Aging",
        url: "https://www.livestrong.com/article/13763590-healthy-aging-tips-from-longevity-experts/",
    },
    {
        website: "MEDIUM.COM",
        title: "Anti-Aging Creams Are Great But Have You Tried What the World’s Oldest People Suggested?",
        url: "https://medium.com/mind-cafe/anti-aging-creams-are-great-but-have-you-tried-what-the-worlds-oldest-people-suggested-db62e0a3d13e",
    },
]

const Article = ({ website, title, link }) => {
    return (
        <div className="article">
            <h6>{website}</h6>
            <h3>{title}</h3>
            <a href={link} target="_blank" rel="noreferrer">
                Read more
            </a>
        </div>
    )
}

const Articles = () => {
    return (
        <div className="container">
            <h3>Articles</h3>
            <div className="articles">
                {data.map(({ website, title, url }) => (
                    <Article
                        website={website}
                        title={title}
                        link={url}
                        key={title}
                    />
                ))}
            </div>
        </div>
    )
}

export default Articles
