import React from 'react'
import {Link} from 'gatsby'

import icon from "../../../static/images/pillars/pillar-icon.svg"

const Pillar = ({id, link, image, title, content, homepage, description }) => {
    return (
        <div className="container">
            <span className="pillar__number">
                    Pillar {id}
            </span>
            <div className="pillar__container">
                
                <div className="pillar__image">
                    <img src={image} alt={title} />
                </div>
                <div className={`pillar__content ${description && "description"}`} >
                    <div className="pillar__title">
                        <img src={icon} alt="" />
                        <h3>{title}</h3>
                    </div>
                    <p>{content}</p>
                    {homepage ? (
                    <Link to={`pillars/${link}`} >
                        <button className="button button__secondary-red">
                            Learn more
                        </button>
                    </Link>
                        ) : (
                            <Link to={link} >
                                <button className="button button__secondary-red">
                                    Learn more
                                 </button>
                            </Link>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Pillar
