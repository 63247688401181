import React from 'react'
import Pillar from './pillar'

import {pillarsData} from "../../data/pillarsData"

const Pillars = ( props ) => {

    return (
        <div className="pillars-list" id="pillars">
            {pillarsData.map( ( { id, title, excerpt, image, link } ) => (
                <div className="pillar" key={id}>
                    <Pillar key={id} id={id} title={title} content={excerpt} image={image} link={link} {...props} />
                </div>
            ))}
        </div>
    )
}

export default Pillars
