import React from "react"
import { Link } from "gatsby"

import icon from "../../static/images/pillars/pillar-icon.svg"

const LongevitySessionsBanner = () => {
    return (
        <div className="longevity-sessions-banner">
            <div className="container">
                <div className="longevity-sessions__title">
                    <img src={icon} className="icon" alt="" />
                    <div>
                        <h2>
                            Longevity Sessions <br />– off the mat - lessons
                            from the studio of&nbsp;life.
                        </h2>
                    </div>
                </div>
                <p>
                    Click <strong>Learn More</strong> to join&nbsp;us and hear
                    from people we admire on this longevity journey.{" "}
                </p>
                <Link className="button" to="/longevity-sessions">
                    Learn more
                </Link>
            </div>
        </div>
    )
}

export default LongevitySessionsBanner
