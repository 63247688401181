import React from "react"
import Slider from "react-slick"

import { testimonialsData } from "../data/testimonialsData"

import icon from "../../static/icons/quote.svg"
import left from "../../static/icons/arrow-left.svg"
import right from "../../static/icons/arrow-right.svg"

const Item = ({ testimonial, auth }) => {
    return (
        <div className="carousel__item">
            <img className="icon" src={icon} alt="quote icon" />
            <i className="carousel__text">"{testimonial}"</i>
            <p className="carousel__author">– {auth}</p>
        </div>
    )
}

const ArrowLeft = ({ onClick }) => {
    return (
        <img
            className="arrow-icon prev"
            src={left}
            alt="left arrow"
            onClick={onClick}
            onKeyDown={onClick}
        />
    )
}
const ArrowRight = ({ onClick }) => {
    return (
        <img
            className="arrow-icon next"
            src={right}
            alt="right arrow"
            onClick={onClick}
            onKeyDown={onClick}
        />
    )
}

const Carousel = () => {
    var settings = {
        centerMode: true,
        centerPadding: "0",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    return (
        <div className="container carousel">
            <h3>Testimonials</h3>
            <Slider {...settings}>
                {testimonialsData.map(({ text, author }) => (
                    <Item auth={author} testimonial={text} key={author} />
                ))}
            </Slider>
        </div>
    )
}

export default Carousel
