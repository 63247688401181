import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Hero = () => {
    const query = useStaticQuery(
        graphql`
            query HeroImg {
                contentfulAsset(title: { eq: "Hero Img" }) {
                    gatsbyImageData(
                        width: 600
                        placeholder: TRACED_SVG
                        formats: JPG
                    )
                }
            }
        `
    )

    const heroImage = getImage(query.contentfulAsset.gatsbyImageData)

    return (
        <div className="hero">
            <div className="container">
                <div className="hero__title">
                    <h1>
                        <span className="red">Functional Aging </span>
                        and&nbsp;<span className="red">Brain&nbsp;Health </span>
                        training programs
                    </h1>
                    <h3>
                        Fitness programs for individuals 60+ and&nbsp;elderly
                        in&nbsp;Fairfield&nbsp;County and New&nbsp;York{" "}
                    </h3>
                    <a className="button" href="#pillars">
                        Learn more
                    </a>
                </div>
                <div className="hero__image">
                    <GatsbyImage image={heroImage} alt="People excercising" />
                </div>
            </div>
        </div>
    )
}

export default Hero
